

@import 'tailwindcss/base';
@import './fonts.css';
@import './prism.css';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
    --shiki-color-text: theme('colors.white');
    --shiki-token-constant: theme('colors.emerald.400');
    --shiki-token-string: theme('colors.emerald.400');
    --shiki-token-comment: theme('colors.zinc.500');
    --shiki-token-keyword: theme('colors.sky.400');
    --shiki-token-parameter: theme('colors.pink.400');
    --shiki-token-function: theme('colors.violet.400');
    --shiki-token-string-expression: theme('colors.emerald.400');
    --shiki-token-punctuation: theme('colors.zinc.300');
  }

::-webkit-scrollbar {
    width: 6px;
    height: 6px; /* Add height for horizontal scrollbar */
  }
  
::-webkit-scrollbar-track {
    background-color: transparent;
  }
::-webkit-scrollbar-thumb {
    background-color: #d1d5db80;
    border-radius: 3px;
  }
  
  /* Add styles for horizontal scrollbar */
::-webkit-scrollbar-corner {
    background-color: transparent;
  }


  /* pre[class*='language-'] {
    color: theme('colors.slate.50');
  }
  
  .token.tag,
  .token.class-name,
  .token.selector,
  .token.selector .class,
  .token.selector.class,
  .token.function {
    color: theme('colors.pink.400');
  }
  
  .token.attr-name,
  .token.keyword,
  .token.rule,
  .token.pseudo-class,
  .token.important {
    color: theme('colors.slate.300');
  }
  
  .token.module {
    color: theme('colors.pink.400');
  }
  
  .token.attr-value,
  .token.class,
  .token.string,
  .token.property {
    color: theme('colors.sky.300');
  }
  
  .token.punctuation,
  .token.attr-equals {
    color: theme('colors.slate.500');
  }
  
  .token.unit,
  .language-css .token.function {
    color: theme('colors.teal.200');
  }
  
  .token.comment,
  .token.operator,
  .token.combinator {
    color: theme('colors.slate.400');
  } */
  